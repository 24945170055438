import { Button, Container } from "@util/standard";
import React, { useState } from "react";
import {
  SanityBlog,
  SanityRecipe,
  SanityRecipeConnection,
} from "@graphql-types";

import BlogPreview from "../components/blogs/blogPreview";
import RecipePreview from "./recipePreview";
import { useStore } from "@state/store";

interface Props {
  allRecipes?: boolean;
  recipes?: any;
  algoliaRecipes?: any;
  isBlogs?: boolean;
  blogs?: any;
  hideSearchResults?: boolean;
  allSanityRecipe?: SanityRecipeConnection;
  iban?: string;
}

const PreviewAll = (props: Props) => {
  const {
    allRecipes,
    recipes,
    algoliaRecipes,
    isBlogs,
    blogs,
    hideSearchResults,
    allSanityRecipe,
    iban,
  } = props;
  const [shownNumber, setShownNumber] = useState(15);

  const { searchParams } = useStore();

  //Sort data
  let recipeData = [];
  let isSearchResult = false;
  if (!isBlogs) {
    if (allRecipes && allSanityRecipe && allSanityRecipe.nodes) {
      recipeData = allSanityRecipe.nodes;
    }

    if (!allRecipes && recipes) {
      recipeData = recipes;
    }

    if (!allRecipes && !recipes && algoliaRecipes) {
      recipeData = algoliaRecipes;
      isSearchResult = true;
    }
  }

  if (
    (recipeData == null || recipeData.length < 1) &&
    (blogs == null || blogs.length < 1)
  ) {
    return <p>Ops, there is nothing in here!</p>;
  }

  return (
    <Container
      display="flex"
      flexDirection="column"
      margin="0px auto 100px 0px"
    >
      {searchParams.searchString.length > 0 &&
        !isBlogs &&
        !hideSearchResults && (
          <h2>Search Results for '{searchParams.searchString}'</h2>
        )}
      <Container
        display="inline-flex"
        flexDirection="row"
        flexWrap="wrap"
        alignItems="center"
        margin="0px auto 30px 0px"
      >
        {!isBlogs &&
          recipeData.slice(0, shownNumber).map((recipe: SanityRecipe, i) => {
            const filteredRegions = recipe.regions?.filter(
              region => region?.iban === iban
            );
            if (filteredRegions?.length == 0) return null;

            return (
              <RecipePreview
                key={i}
                isSearchResult={isSearchResult}
                recipe={recipe}
              />
            );
          })}
        {isBlogs &&
          blogs.slice(0, shownNumber).map((blog: SanityBlog, i) => {
            return <BlogPreview hasMarginBottom blog={blog} key={i} />;
          })}
      </Container>

      {recipeData && shownNumber < recipeData.length && (
        <Button
          onClick={() => {
            setShownNumber(pre => pre + 15);
          }}
          mobileMargin="auto"
        >
          Load More
        </Button>
      )}
      {blogs && shownNumber < blogs.length && (
        <Button
          onClick={() => {
            setShownNumber(pre => pre + 15);
          }}
          mobileMargin="auto"
        >
          Load More
        </Button>
      )}
    </Container>
  );
};

export default PreviewAll;
